<template>
	<div>
		<Header title="奖品列表" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<cTable ref="cTable" isPage isRequest :searchData="{activityId: id}" :requestFun="getActivityGiftList" :columns="columns" :scroll="{x: 1500}">
				<template #bodyCell="{column, record, index}">
					<template v-if="column.key === 'index'">
						{{ index + 1 }}
					</template>
					<template v-if="column.key === 'diffNum'">
						{{ record.limitNum - (record.successNum || 0) }}
					</template>
					<template v-if="column.key === 'type'">
						<div>
							{{ ['', '实体商品', '无奖品(谢谢参与)', '优惠券', '积分', '一卡通'][record.type] }}
						</div>
					</template>
					<template v-if="column.key === 'image'">
						<a-image :src="record.giftImage" style="width: 60px;"></a-image>
					</template>
					<template v-if="column.key === 'goods'">
						<div>
							<div v-if="record.type === 1">
								{{ record.giftMessage.title }}
							</div>
							<div v-else-if="record.type === 3">
								{{ record.giftMessage.title }}
							</div>
							<div v-else-if="record.type === 4">{{ record.giftNum }}积分</div>
							<div v-else-if="record.type === 5">
								{{ record.giftMessage.title }}
							</div>
							<div v-else>-</div>
						</div>
					</template>
					<template v-if="column.key === 'action'">
						<a-button v-permission="['operation_activity_lottery_prize_edit']" type="link" @click="onEdit(record)">编辑</a-button>
					</template>
				</template>
			</cTable>
		</a-spin>
		
		<a-modal v-model:visible="showModal" title="编辑" width="750px" @cancel="onCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="modelRef" :labelCol="{ span: 5 }" :wrapperCol="{span: 18}">
					<a-form-item label="奖项名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.name" placeholder="请输入奖项名称"></a-input>
					</a-form-item>
					
					<a-form-item label="奖项图片" name="giftImage" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-upload v-model:file-list="fileList" list-type="picture-card"
							action="/admin/ajaxUpload.do" accept="image/*"
							@change="onUploadChange"
						>
							<div v-if="fileList.length < 1">
								<Icon icon="PlusOutlined"></Icon>
								<div style="margin-top: 8px">Upload</div>
							</div>
						</a-upload>
					</a-form-item>
					
					<a-form-item label="数量" name="limitNum" :rules="[{required: true, message: '必填项不允许为空'}]" extra="0为无限制">
						<a-input-number v-model:value="modelRef.limitNum" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="中奖概率" name="probability" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input-number v-model:value="modelRef.probability" :min="0" placeholder="请输入"></a-input-number> %
					</a-form-item>
					
					<a-form-item label="排序" name="sort" :rules="[{required: true, message: '必填项不允许为空'}]" extra="值越大越靠前">
						<a-input-number v-model:value="modelRef.sort" :min="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="奖品类型" name="type" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-select v-model:value="modelRef.type" placholder="请选择" @change="onTypeChange">
							<a-select-option :value="1">实物商品</a-select-option>
							<a-select-option :value="2">无奖品(谢谢参与)</a-select-option>
							<a-select-option :value="3">优惠券</a-select-option>
							<a-select-option :value="4">积分</a-select-option>
							<a-select-option :value="5">一卡通</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="奖品" name="gift">
						<a-form-item-rest>
							<selectGoods v-model:value="modelRef.gift" type="radio" :deliveryType="1" v-if="modelRef.type === 1"></selectGoods>
							<selectCoupon v-model:value="modelRef.gift" type="radio" v-else-if="modelRef.type === 3"></selectCoupon>
							<a-input-number v-model:value="modelRef.gift" v-else-if="modelRef.type === 4" :min="0" :precision="0" placeholder="请输入"></a-input-number>
							<selectOneCard v-model:value="modelRef.gift" type="radio" v-else-if="modelRef.type === 5"></selectOneCard>
							<div v-else>-</div>
						</a-form-item-rest>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import cTable from '@/components/cTable/index.vue';
	import selectGoods from '@/components/select/selectGoods/index.vue';
	import selectCoupon from '@/components/select/selectCoupon/index.vue';
	import selectOneCard from '@/components/select/selectOneCard/index.vue';
	import { getActivityGiftList, getActivityGiftDetail, updateActivityGift } from '@/service/modules/operation.js';
	export default {
		components: { Icon, Header, cTable, selectGoods, selectCoupon, selectOneCard },
		props: {
			id: { // 活动id
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				columns: [{
					title: '序号',
					key: 'index'
				}, {
					title: '奖项名称',
					dataIndex: 'name'
				}, {
					title: '奖项图片',
					key: 'image'
				}, {
					title: '中奖概率%',
					dataIndex: 'probability'
				}, {
					title: '数量(0为无限制)',
					dataIndex: 'limitNum'
				}, {
					title: '中奖次数',
					dataIndex: 'successNum'
				}, {
					title: '剩余中奖次数',
					key: 'diffNum'
				}, {
					title: '奖品类型',
					key: 'type'
				}, {
					title: '奖品',
					key: 'goods'
				}, {
					title: '排序',
					dataIndex: 'sort' 
				}, {
					title: '操作',
					fixed: 'right',
					key: 'action',
					width: 100
				}],
				showModal: false,
				modelRef: {}
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getActivityGiftList: getActivityGiftList,
			getData() {
				this.$refs.cTable.toQuery();
			},
			async onEdit(item) {
				this.loading = true;
				let ret = await getActivityGiftDetail({
					id: item.id
				})
				this.loading = false;
				if (ret.code === 200) {
					if ([1,3,5].includes(ret.data.type)) {
						ret.data.gift = [ret.data.gift];
					}
					this.fileList = [{
						uid: 0,
						name: ret.data.giftImage,
						url: ret.data.giftImage
					}]
					this.modelRef = ret.data;
					this.showModal = true;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					if ([1,3,5].includes(postData.type)) {
						postData.gift = postData.gift[0];
					}
					this.loading = true;
					let ret = await updateActivityGift(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.getData();
						this.onCancel();
					}
				})
			},
			onCancel() {
				this.$refs.formRef.resetFields();
				this.showModal = false;
			},
			onTypeChange() {
				this.modelRef.gift = undefined;
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					this.fileList = info.fileList.filter(file => {
						if (file.response) {
							this.modelRef.giftImage = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
							return true;
						}
					});
				}
			},
		}
	}
</script>

<style>
</style>