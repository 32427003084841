<template>
	<div>
		<Header :title="isEdit ? '修改抽奖活动' : '新增抽奖活动'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 4, xxl: 3}" :wrapperCol="{span: 19, xxl: 18 }" @finish="onSubmit">

				<a-row>
					<a-col :span="24">
						<a-form-item label="活动名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="modelRef.title" placeholder="请输入活动名称"></a-input>
						</a-form-item>

						<a-form-item label="抽奖消耗积分" name="point" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="modelRef.point" placeholder="请输入抽奖消耗积分"></a-input>
						</a-form-item>

						<a-form-item label="每日可抽奖数" name="limitNum" :rules="[{required: true, message: '必填项不允许为空'}]" extra="0则不限制">
							<a-input v-model:value="modelRef.limitNum" placeholder="请输入每日可抽奖数"></a-input>
						</a-form-item>

						<a-form-item label="奖品过期时间" name="limitTime" :rules="[{required: true, message: '必填项不允许为空'}]">
							中奖后 <a-input-number v-model:value="modelRef.limitTime" :min="1" :precision="0" placeholder="请输入"></a-input-number> 秒过期
						</a-form-item>

						<a-form-item label="活动时间" name="time" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-range-picker v-model:value="modelRef.time" showTime></a-range-picker>
						</a-form-item>
						
						<a-form-item label="状态" name="isDisabled" >
							<a-radio-group v-model:value="modelRef.isDisabled">
								<a-radio :value="0">启用</a-radio>
								<a-radio :value="1">禁用</a-radio>
							</a-radio-group>
						</a-form-item>

						<a-form-item label="页面主色调" name="backgroundColor" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="modelRef.backgroundColor" placeholder="请输入颜色值,如:#fc6e58"></a-input>
						</a-form-item>

						<a-form-item label="顶部背景图片" name="topImage" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-upload v-model:file-list="topImageFileList" list-type="picture-card"
								action="/admin/ajaxUpload.do" accept="image/*"
								@change="(file) => onUpload(file, 'topImage')"
							>
								<div v-if="topImageFileList.length < 1">
									<Icon icon="PlusOutlined"></Icon>
									<div style="margin-top: 8px">Upload</div>
								</div>
							</a-upload>
							<a-button type="link" size="small" @click="onView(topImg)">查看示例图片</a-button>
						</a-form-item>

						<a-form-item label="转盘背景图片" name="turntableImage" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-upload v-model:file-list="turntableImageFileList" list-type="picture-card"
								action="/admin/ajaxUpload.do" accept="image/*"
								@change="(file) => onUpload(file, 'turntableImage')"
							>
								<div v-if="turntableImageFileList.length < 1">
									<Icon icon="PlusOutlined"></Icon>
									<div style="margin-top: 8px">Upload</div>
								</div>
							</a-upload>
							<a-button type="link" size="small"  @click="onView(bgImg)">查看示例图片</a-button>
						</a-form-item>

						<a-form-item label="活动规则图片" name="ruleImage" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-upload v-model:file-list="ruleImageFileList" list-type="picture-card"
								action="/admin/ajaxUpload.do" accept="image/*"
								@change="(file) => onUpload(file, 'ruleImage')"
							>
								<div v-if="ruleImageFileList.length < 1">
									<Icon icon="PlusOutlined"></Icon>
									<div style="margin-top: 8px">Upload</div>
								</div>
							</a-upload>
							<a-button type="link" size="small" @click="onView(ruleImg)">查看示例图片</a-button>
						</a-form-item>

						<a-form-item label="抽奖按钮图片" name="buttonImage" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-upload v-model:file-list="buttonImageFileList" list-type="picture-card"
								action="/admin/ajaxUpload.do" accept="image/*"
								@change="(file) => onUpload(file, 'buttonImage')"
							>
								<div v-if="buttonImageFileList.length < 1">
									<Icon icon="PlusOutlined"></Icon>
									<div style="margin-top: 8px">Upload</div>
								</div>
							</a-upload>
							<a-button type="link" size="small" @click="onView(btnImg)">查看示例图片</a-button>
						</a-form-item>

						<a-form-item label="奖品配置" v-if="!id">
							<a-table :pagination="false" :dataSource="list" :columns="columns">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'index'">
										{{ index + 1 }}
									</template>
									<template v-if="column.key === 'sort'">
										<a-input-number v-model:value='record.sort' :min="0" :precision="0" placeholder="请输入"></a-input-number>
									</template>

									<template v-if="column.key === 'name'">
										<a-input v-model:value="record.name" placeholder="请输入"></a-input>
									</template>

									<template v-if="column.key === 'image'">
										<a-upload list-type="picture-card" accept="image/*" :show-upload-list="false"
											action="/admin/ajaxUpload.do"
											@change="(file) => onUploadChange(file, index)">
											<div v-if="!record.giftImage">
												<Icon icon="PlusOutlined"></Icon>
												<div style="margin-top: 8px">Upload</div>
											</div>
											<div v-else>
												<a-image :src="record.giftImage"></a-image>
											</div>
										</a-upload>
									</template>

									<template v-if="column.key === 'num'">
										<a-input-number v-model:value="record.limitNum" :min="0" :precision="0"
											placeholder="请输入"></a-input-number>
									</template>

									<template v-if="column.key === 'chance'">
										<a-input-number v-model:value="record.probability" :min="0"
											placeholder="请输入"></a-input-number> %
									</template>

									<template v-if="column.key === 'type'">
										<a-select v-model:value="record.type" placeholder="请选择" @change="onTypeChange(index)">
											<a-select-option :value="1">实物商品</a-select-option>
											<a-select-option :value="2">无奖品(谢谢参与)</a-select-option>
											<a-select-option :value="3">优惠券</a-select-option>
											<a-select-option :value="4">积分</a-select-option>
											<a-select-option :value="5">一卡通</a-select-option>
										</a-select>
									</template>

									<template v-if="column.key === 'goods'">
										<div>
											<selectGoods v-model:value="record.gift" type="radio" :deliveryType="1" v-if="record.type === 1"></selectGoods>
											<selectCoupon v-model:value="record.gift" type="radio" v-else-if="record.type === 3"></selectCoupon>
											<a-input-number v-model:value="record.gift" v-else-if="record.type === 4" :min="0" :precision="0"
												placeholder="请输入"></a-input-number>
											<selectOneCard v-model:value="record.gift" type="radio" v-else-if="record.type === 5"></selectOneCard>
											<div v-else>-</div>
										</div>
									</template>
								</template>
							</a-table>
						</a-form-item>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
		<a-image :width="200" :style="{ display: 'none' }" :preview="{ visible, onVisibleChange: setVisible, }" :src="previewSrc"></a-image>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import selectGoods from '@/components/select/selectGoods/index.vue';
	import selectCoupon from '@/components/select/selectCoupon/index.vue';
	import selectOneCard from '@/components/select/selectOneCard/index.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import bgImg from '@/assets/image/bg.png';
	import topImg from '@/assets/image/top.png';
	import btnImg from '@/assets/image/btn-img.png';
	import ruleImg from '@/assets/image/rule.png';
	import {
		getActivityDetail,
		saveActivity,
		updateActivity
	} from '@/service/modules/operation.js';
	export default {
		components: {
			Header,
			Icon,
			selectGoods,
			selectCoupon,
			selectOneCard
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		data() {
			return {
				visible: false,
				previewSrc: '',
				bgImg,
				topImg,
				btnImg,
				ruleImg,
				loading: false,
				topImageFileList: [],
				turntableImageFileList: [],
				ruleImageFileList: [],
				buttonImageFileList: [],
				modelRef: {
					isDisabled: 0
				},
				columns: [{
					title: '序号',
					key: 'index',
					width: 70
				},
				// {
				// 	title: '排序',
				// 	key: 'sort',
				// 	width: 70
				// }, 
				{
					title: '奖项名称',
					key: 'name',
					width: 180
				}, {
					title: '奖项图片（建议比例1:1）',
					key: 'image',
					width: 150
				}, {
					title: '数量(0为不限制)',
					key: 'num',
					width: 130
				}, {
					title: '中奖概率%',
					key: 'chance',
					width: 140
				}, {
					title: '奖品类型',
					key: 'type',
					width: 150
				}, {
					title: '奖品',
					key: 'goods',
					width: 150
				}],
				list: [{
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}, {
					probability: 0,
					limitNum: 0
				}],
			}
		},
		methods: {
			async getData() {
				this.loading = true;
				let ret = await getActivityDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					if (ret.data.topImage) {
						this.topImageFileList = [{
							uid: 1,
							name: ret.data.topImage,
							url: ret.data.topImage
						}]
					}
					if (ret.data.turntableImage) {
						this.turntableImageFileList = [{
							uid: 1,
							name: ret.data.turntableImage,
							url: ret.data.turntableImage
						}]
					}
					if (ret.data.ruleImage) {
						this.ruleImageFileList = [{
							uid: 1,
							name: ret.data.ruleImage,
							url: ret.data.ruleImage
						}]
					}
					if (ret.data.buttonImage) {
						this.buttonImageFileList = [{
							uid: 1,
							name: ret.data.buttonImage,
							url: ret.data.buttonImage
						}]
					}
					ret.data.time = [this.moment(ret.data.startTime *1000), this.moment(ret.data.endTime *1000)];
					this.modelRef = ret.data;
				}
			},
			async onSubmit() {
				let postData = this.$deepClone(this.modelRef);
				if (postData.time) {
					postData.startTime = postData.time[0].unix();
					postData.endTime = postData.time[1].unix();
					delete postData.time;
				}
				let ret;
				this.loading = true;
				if (this.id) {
					// 编辑没有奖品配置
					ret = await updateActivity(postData);
				} else {
					// 新增
					let canSubmit = true;
					let list = JSON.parse(JSON.stringify(this.list));
					list.forEach(item => {
						if ((typeof item.gift).indexOf('object') !== -1) {
							item.gift = item.gift[0];
						}
						if (item.type !== 2 && (!item.gift && item.gift !== 0)) {
							canSubmit = false;
						}
						if (!item.name.trim()) {
							canSubmit = false;
						}
						if (!item.giftImage) {
							canSubmit = false;
						}
					})
					if (!canSubmit) {
						this.loading = false;
						return this.$message.warn('请检查奖品配置已全部输入完成');
					}
					postData.giftList = list;
					ret = await saveActivity(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.onBack(true);
				}
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onTypeChange(index) {
				this.list[index].gift = undefined;
			},
			onUpload(info, key) {
				if (info.file.status === 'done') {
					this[key + 'FileList'] = info.fileList.filter(file => {
						if (file.response) {
							this.modelRef[key] = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
							return true;
						}
					});
				}
			},
			onUploadChange(info, index) {
				if (info.file.status === 'done') {
					info.fileList.forEach(file => {
						if (file.response) {
							this.list[index].giftImage = file.response.data.imgUrl;
						}
					});
				}
			},
			onView(src) {
				this.previewSrc = src;
				this.setVisible(true);
			},
			setVisible(value) {
				this.visible = value;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>