<template>
	<div>
		<Header title="参与抽奖记录" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch" style="margin-top: 20px;">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="title" label="活动名称">
						<a-input v-model:value="formState.title" placeholder="请输入活动名称"></a-input>
					</a-form-item>
			
					<a-form-item class="ui-form__item" name="isDisabled" label="状态">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
			
				</a-row>
			
				<a-row>
					<a-col :span="18">
						<div>参与总人数：{{ dataCount.num || 0 }}</div>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<cTable ref="cTable" isPage isRequest :searchData="searchData" :requestFun="getActivityRecord" :columns="columns" :scroll="{ x: 1500 }" isReport @reporting="getReporting">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'userInfo'">
						<div>
							<div>用户昵称：{{ record.nickname || '-' }}</div>
							<div>用户手机号：{{ record.phone || '-' }}</div>
						</div>
					</template>
					<template v-if="column.key === 'type'">
						<div>
							{{ ['', '实体商品', '无奖品(谢谢参与)', '优惠券', '积分', '一卡通'][record.giftType] }}
						</div>
					</template>
					<template v-if="column.key === 'giftStatus'">
						{{ ['-', '已领取', '待领取', '已发放', '发放失败'][record.giftStatus] }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.lotteryTime) }}
					</template>
					<template v-if="column.key === 'diffNum'">
						{{ record.limitNum - (record.successNum || 0) }}
					</template>
					<template v-if="column.key === 'giftName'">
						{{ record.gift.name || '-' }}
					</template>
					<template v-if="column.key === 'giftImage'">
						<a-image :src="record.gift.giftImage" style="width: 60px;"></a-image>
					</template>
					<template v-if="column.key === 'action'">
						<a-button v-permission="['operation_activity_lottery_record_send']" v-if="record.giftStatus === 4" @click="onResend(record)">补发奖品</a-button>
					</template>
				</template>
			</cTable>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getActivityRecord, supplementGift } from '@/service/modules/operation.js';
	export default {
		components: { Header, cTable },
		props: {
			id: { // 活动id
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				dataCount: {
					num: 0
				},
				formState: {},
				searchData: {},
				columns: [{
					title: '用户信息',
					key: 'userInfo'
				}, {
					title: '奖项名称',
					key: 'giftName'
				}, {
					title: '奖项图片',
					key: 'giftImage',
					width: 100
				}, {
					title: '奖品类型',
					key: 'type',
					width: 120
				}, {
					title: '奖品',
					dataIndex: 'goodsName'
				}, {
					title: '奖品发放状态',
					key: 'giftStatus',
					width: 120
				}, {
					title: '积分消耗',
					dataIndex: 'point',
					width: 120
				}, {
					title: '中奖时间',
					key: 'createTime',
					width: 180
				}, {
					title: '订单号',
					dataIndex: 'orderNo'
				}, {
					title: '操作',
					fixed: 'right',
					key: 'action',
					width: 100
				}]
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getActivityRecord: getActivityRecord,
			reset() {
				this.$refs.form.resetFields();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.activityId = this.id;
				this.getData(true);
			},
			getData(isRef) {
				if (this.isRef) {
					this.$refs.cTable.refQuery();
				} else {
					this.$refs.cTable.toQuery();
				}
			},
			onResend(item) {
				this.$confirm({
					title: '提示',
					content: '确定执行该操作吗？',
					onOk: async ()=>{
						this.loading = true;
						let ret = await supplementGift({
							recordId: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功')
							this.getData()
						}
					}
				})
			},
			getReporting(data) {
				this.dataCount = data;
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>